
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CleeQ from './Pages/CleeQ/CleeQ';
import Banking from './Pages/Banking/Banking';
import CleeQChat from './Pages/CleeQChat/CleeQChat';
import Pricing from './Pages/Pricing/Pricing';
import CleeQFunding from './Pages/CleeQFunding/CleeQFunding';
import CleeQX from './Pages/CleeQX/CleeQX';
import CleeQInvest from './Pages/CleeQInvest/CleeQInvest';

function App() {
  return ( 
    <div> 
      <Router>
        <Switch>  
          <Route path="/" exact component={CleeQ} /> 
          {/* <Route path="/Banking" exact component={Banking} /> 
          <Route path="/CleeQInvest" exact component={CleeQInvest} /> 
          <Route path="/CleeQChat" exact component={CleeQChat} /> 
          <Route path="/Pricing" exact component={Pricing} /> 
          <Route path="/CleeQFunding" exact component={CleeQFunding} /> 
          <Route path="/CleeQX" exact component={CleeQX} />   */}
        </Switch>
      </Router> 
    </div>
  );
}

export default App;




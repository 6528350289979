import { React, useEffect } from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import OutflowPricing from "./OutflowPricing";
import CleeQTransactions from "./CleeQTransactions";
import CleeQTransacitionHeading from "./CleeQTransacitionHeading";
import Aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Pricing() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const classes = useStyles();
  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>
        <section>
          <br />
          <br />
          <br />
          <br />

          <div data-aos="fade-up">
            <OutflowPricing />
          </div>
          <br />
      

          <div data-aos="fade-up">
            <CleeQTransacitionHeading />
          </div>

          <br />
          <div data-aos="fade-up">
            <CleeQTransactions />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default Pricing;

import React from "react";
import SearchBox from "../../Components/SearchBox";

function OurLanding() {
  return (
    <div className="container LandingCont">
      <div className="LandingWrapper">
        <div className="LandingContainer">
          <br /> <br />
          <div className="LandingCoverUp">
            <div className="LandingHedings1" data-aos="zoom-in-up">
              Effortless Bill Payment Solutions
            </div>
            {/* <span className="LaNdBank">Every Times</span> */}
            <div className="CleeQSlogan1">Seamless Payments, Every Time</div>
          </div>
          <div className="CleeQSlogan">We are launching soon!</div>
        </div>
        <div className="LandImg02">
          <img src="/assets/OR.png" alt="" className="LandingImg" />
        </div>
      </div>
    </div>
  );
}

export default OurLanding;

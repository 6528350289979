// import React from "react";
import  { React, useEffect } from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import Footer from "../../Components/Footer/Footer";
import Bank from "./Bank";
import BankInStyle from "./BankInStyle";
import WhyBankWithUs from "./WhyBankWithUs";
import Request from "./Request";
import Aos from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Banking() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const classes = useStyles();

  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>

        <section>
          <div >
            <Bank />
          </div>
         
         <br/>  <br/>  <br/>  <br/>  <br/>
          <div data-aos="fade-up">
            <BankInStyle />
          </div>
          <br/>  <br/>  <br/>
          <div data-aos="fade-up">
            <Request />
          </div>
          <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
          <div data-aos="fade-up">
            <WhyBankWithUs />
          </div>
          <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
          <div data-aos="fade-up">
            <JoinTheCleeQ />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default Banking;

// import React from "react";
import  { React, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";
import JoinTheCleeQ from "../CleeQ/JoinTheCleeQ";
import Footer from "../../Components/Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../../Components/NavBar/NavBar";
import Chat from "./Chat";
import StayConnected from "./StayConnected";
import ChatSearvices from "./ChatSearvices";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function CleeQChat() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const classes = useStyles();
  return (
    <div>
      <div className="CleeQBody">
        <header className="CleeQSticky">
          <NavBar />
        </header>

        <section>
          <div data-aos="fade-up">
            <Chat />
          </div>

         <br/>   <br/>   <br/><br/>   <br/>   <br/><br/>   <br/>   <br/>

          <div data-aos="fade-up">
            <StayConnected />
          </div>

          <div data-aos="fade-up">
            <ChatSearvices />
          </div>
        </section>
        <footer className="CleeQFooter">
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default CleeQChat;

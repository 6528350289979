import {
  AccountTree,
  AssignmentReturnedOutlined,
  DonutSmallOutlined,
  EmojiEventsOutlined,
  GamesOutlined,
  MeetingRoom,
  ThumbDownOutlined,
} from "@material-ui/icons";
import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function OurServices() {
  return (
    <div className="container JoinCont3">
      <div className="PacCont">
        <div className="container p-0 PacLanding overflow-hidden ">
          <div className="TaskWrapper">
            <center className="WhyChooseWrapper">
              <center className="WhyChooseContainer">
                Some of our special features include
              </center>{" "}
              {/* <center className="WhySub">
                We've provided a list of contents to assist you in arranging
                your CleeQ effectively.
              </center> */}
            </center>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row g-2">
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <MeetingRoom className="AccIcon" />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Electricity Bills</div>
                            <div className="ACCSub">
                            Keep the Lights On, Pay with Ease
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="">
                            <div className="IconCont1">
                              <AccountTree className="AccIcon" />

                              {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Buy Airtime</div>
                            <div className="ACCSub">
                            Stay Connected, Top Up Instantly.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <EmojiEventsOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Event Tickets</div>
                            <div className="ACCSub">
                            Your Ticket to Unforgettable Moments
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row g-2">
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <ThumbDownOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Betting</div>
                            <div className="ACCSub">
                              Fund your betting wallet with CleeQ
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <GamesOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Buy Data</div>
                            <div className="ACCSub">
                            Stay Online, Top Up Anytime
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <DonutSmallOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Cable Payment</div>
                            <div className="ACCSub">
                            Stream Seamlessly, Pay with Ease.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <div className="container p-0  overflow-hidden">
              <div className="row g-2">
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />

                            {/* <img src="/assets/2001.png" alt="" className="AccFR"/> */}
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Donations</div>
                            <div className="ACCSub">
                            Give Hope, Make a Difference.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Contributions </div>
                            <div className="ACCSub">
                            Your Contribution, Their Better Tomorrow.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="">
                    <div className="container-fluid p-0">
                      <div className="row g-2">
                        <div className="col-xl-3">
                          <div className="IconCont1">
                            <AssignmentReturnedOutlined className="AccIcon" />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="">
                            <div className="ACCHeading">Flight Payment </div>
                            <div className="ACCSub">
                            Book Your Journey, Pay with Ease.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;

import React from "react";
import "../../Pages/CleeQ.css";
import "../../Pages/Responsive.css";

function Footer() {
  return (
    <div className="container FooterWrapper">
      {/* <div className="PacCont">
        <div className="container p-0 PacLanding overflow-hidden">
          <div className="row gx-3">
            <div className="col-12">
              <div className="row gx-3">
                <div className="col-4">
                  <div className="">
                    <div>
                      Contact us
                    </div>
                  </div>
                </div>
                <div className="col-4">
                <div className="">
                    <div>
                      Contact us
                    </div>
                  </div>
                </div>
                <div className="col-4">
                <div className="">
                    <div>
                      Contact us
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className=" container-fluid FoterContainer">
                  <div>
                    <img
                      src="/assets/TLogo.png"
                      alt=""
                      className="FooterLogo"
                    />
                  </div>
                  <div className="copyright-text">
                    Copyright &copy;{new Date().getFullYear()} CleeQ24 Ltd. All
                    Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className=" container-fluid FoterContainer">
                  <div>
                    <img
                      src="/assets/TLogo.png"
                      alt=""
                      className="FooterLogo"
                    />
                  </div>
                  <div className="copyright-text">
                    Copyright &copy;{new Date().getFullYear()} CleeQ24 Ltd. All
                    Rights Reserved
                  </div>
                </div>
    </div>
  );
}

export default Footer;
